import React, { useEffect, useState } from 'react';

import SmartSearch from 'ibis-design-system/lib/components/inputFields/SmartSearch';
import {
    addTextFilter,
    addRegionFilter,
    addNatureFilter,
    addRelationFilter,
    addCountryFilter,
    addDigiOfficeFilter,
    addCityFilter,
    addDepartmentFilters,
    addDeltaresContactFilter,
    addDateFilter,
    addGenericFilter
} from 'ibis-design-system/lib/components/inputFields/SmartSearch/filters';
import { isEmptyOrSpaces } from 'ibis-design-system/lib/HelperFunctions/StringHelper';
import { useNavigationState } from 'ibis-design-system/lib/Providers/NavigationProvider';
import { useCacheState } from 'ibis-design-system/lib/Providers/HttpCacheProvider';
import { HttpGet, CancelToken } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { convertToHumanReadable } from '../Utils/convertToHumanReadableText';
import {
    useRegions,
    useCountries,
    useNatures,
    useRelationTypes,
    useUnits,
    useSoftwareAndModels, 
    useSdgs, 
    useDepartments, 
    useExperimentalFacilities, 
    useArchivalReasons, 
    useCompanies, 
    useKeywords,
    useExternalStages,
    useInternalStages,
    useProjectStages,
    useMoonshots
} from '../Hooks/GlobalConfigContext';
import Settings from '../Settings';
import moment from "moment";
import { isProject } from '../Utils/isProject';

function Search(props) {
    const {
        collectionId,
        collectionTab,
        navigateToCollection,
        setEntities,
        setSelection,
        entities
    } = useNavigationState();

    const cache = useCacheState();
    const regions = useRegions();
    const countries = useCountries();
    const natures = useNatures();
    const relations = useRelationTypes();
    const units = useUnits();
    const software = useSoftwareAndModels();
    const experimentalFacilities = useExperimentalFacilities();
    const sdgs = useSdgs();
    const departments = useDepartments();
    const companies = useCompanies();
    const keywords = useKeywords();
    const archivalReasons = useArchivalReasons();
    const externalStages = useExternalStages();
    const internalStages = useInternalStages();
    const projectStages = useProjectStages();
    const moonShots = useMoonshots();

    const [searchInput, setSearchInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [searchFilters, setSearchFilters] = useState([]);

    useEffect(() => {
        props.setFilters(searchFilters.map(x => x.filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilters]);
    
    useEffect(() => {
        const source = CancelToken.source();

        determineFilters(searchInput);

        return () => {
            source.cancel();
       };

        function determineFilters(input) {
            if (isEmptyOrSpaces(input)) {
                setSuggestions([]);
                return;
            }

            input = input?.toLowerCase();
            const filteredSuggestions = [addTextFilter(input)];

            // Local determined filters
            addIdFilters(filteredSuggestions, input);
            addRegionFilters(filteredSuggestions, input);
            addCountryFilters(filteredSuggestions, input);
            addUnitFilters(filteredSuggestions, input);
            addSoftwareFilters(filteredSuggestions, input);
            addDepartmentFilter(filteredSuggestions, input);
            addSdgFilters(filteredSuggestions, input);
            addLoisFilter(filteredSuggestions, input);
            addExternalReferenceFilter(filteredSuggestions, input);
            addExperimentalFacilityFilters(filteredSuggestions, input);
            addNatureFilters(filteredSuggestions, input);
            addRelationFilters(filteredSuggestions, input);
            addMaconomyTenderFilter(filteredSuggestions, input);
            if (!isProject() ? addExternalStageFilter(filteredSuggestions, input) : null);
            if (!isProject() ? addInternalStageFilter(filteredSuggestions, input) : null);
            if (isProject() ? addProjectStageFilter(filteredSuggestions, input) : null);
            addFinancierFilter(filteredSuggestions, input);
            addDeadlineFilter(filteredSuggestions, input);
            addKeywordFilter(filteredSuggestions, input);
            addArchivalReasonFilter(filteredSuggestions, input);
            addMoonShotFilters(filteredSuggestions, input);

            setSuggestions(filteredSuggestions);

            // Server side determined filters (async)
            addCityFilters(input, source);
            addDeltaresContactFilters(input, source);
            addStartDateFilters(input, source);
            addDeadlineFilter(input, source);
        }

        function addDeltaresContactFilters(input, source) {
            if (input.length < 3) return;
            const url = `${Settings.apiUrls.singleSignOn}/users/details/search?skip=0&take=5&term=${input}`;
            const users = cache[url];
            if (users) {
                setUserSuggestions(users);
            } else {
                HttpGet(url, source.token).then(({ result }) => {
                    cache[url] = result;
                    setUserSuggestions(result);
                });
            }
        }
             
        function setUserSuggestions(users) {
            const newSuggestions = [];
            for (let i = 0; i < users.length; i++) {
                newSuggestions.push(addDeltaresContactFilter(users[i], 'contact'));
            }
            setSuggestions(currentSuggestions => currentSuggestions.concat(newSuggestions));
        }

        function addCityFilters(input, source) {
            if (input.length < 3) return;
            const url = `${Settings.apiUrls.cci}/companies/search/city-suggestions?term=${input}`;
            const cities = cache[url];
            if (cities) {
                setCitySuggestions(cities);
            } else {
                HttpGet(url, source.token).then(response => {
                    cache[url] = response;
                    setCitySuggestions(response);
                });
            }
        }

        function addDepartmentFilter(localFilters, input) {
            if (input.length < 2) return;
            const matched = new Set(departments.filter(x => x.abbreviation?.toLowerCase().includes(input)).slice(0, 10));

            for (const department of matched) {
                localFilters.push(addDepartmentFilters('id', department.id, department.abbreviation));
            }
        }

        function addStartDateFilters(input, source) {
            if (input.length < 7) return;
            
            const currentDate = moment.utc().locale('en-GB');
            const startDate = moment.utc(input, 'D-M-YYYY').locale('en-GB');
        
            if (!startDate.isValid()) return;
        
            const formattedStartDate = startDate.format('YYYY-MM-DD');
            const formattedStartDateDisplay = startDate.format("D MMMM YYYY");
            const formattedEndDate = currentDate.format('YYYY-MM-DD');
        
            setSuggestions(currentSuggestions => currentSuggestions.concat(addDateFilter({ start: formattedStartDate, end: formattedEndDate }, `Start date: ${formattedStartDateDisplay}`, `Start date: ${formattedStartDateDisplay}`, 'start')));
        }

        function addDeadlineFilter(input, source) {
            if (input.length < 7) return;
            const date = moment.utc(input, 'D-M-YYYY').locale('en-GB');
            const formatted = moment(date).locale('en-GB').format("D MMMM YYYY")
            const formattedDate = date.format('YYYY-MM-DD');
            if (date === "Invalid date" || formatted === "Invalid date") return;
            setSuggestions(currentSuggestions => currentSuggestions.concat(addDateFilter({start:formattedDate ,end: formattedDate}, `Deadline: ${formatted}`, `Deadline: ${formatted}`, 'deadline')));
        }

        function setCitySuggestions(cities) {
            const newSuggestions = [];
            for (let i = 0; i < cities.length; i++) {
                newSuggestions.push(addCityFilter(cities[i]));
            }
            setSuggestions(currentSuggestions => currentSuggestions.concat(newSuggestions));
        }
        
        function addRelationFilters(localFilters, input) {
            if (input.startsWith('relation')) {
                for (let i = 0; i < relations.length; i++) {
                    localFilters.push(addRelationFilter(relations[i].id, relations[i].label));
                }
                return;
            }

            if (input.length < 3) return;

            const matchedRelations = relations.filter(x => x.label?.toLowerCase().includes(input));

            for (let i = 0; i < matchedRelations.length; i++) {
                localFilters.push(
                    addRelationFilter(matchedRelations[i].id, matchedRelations[i].label)
                );
            }
        }

        function addNatureFilters(localFilters, input) {
            if (input.startsWith('nature')) {
                for (let i = 0; i < natures.length; i++) {
                    localFilters.push(addNatureFilter(natures[i].id, natures[i].label));
                }
                return;
            }

            if (input.length < 3) return;

            const matchedNatures = natures.filter(x => x.label?.toLowerCase().includes(input));

            for (let i = 0; i < matchedNatures.length; i++) {
                localFilters.push(addNatureFilter(matchedNatures[i].id, matchedNatures[i].label));
            }
        }

        function addIdFilters(localFilters, input) {
            if (isNaN(input)) return;
            localFilters.push(addDigiOfficeFilter(input));
        }

        function addRegionFilters(localFilters, input) {
            if (input.startsWith('region')) {
                for (let i = 0; i < regions.length; i++) {
                    localFilters.push(addRegionFilter(regions[i].id, regions[i].name));
                }
                return;
            }
            if (input.length < 2) return;
            const matchedRegions = regions.filter(x => x.name?.toLowerCase().includes(input));

            for (let i = 0; i < matchedRegions.length; i++) {
                localFilters.push(addRegionFilter(matchedRegions[i].id, matchedRegions[i].name));
            }
        }

        function addCountryFilters(localFilters, input) {
            if (input.length < 3) return;
            const matchedCountries = countries.filter(x => x.name?.toLowerCase().includes(input));

            for (let i = 0; i < matchedCountries.length; i++) {
                localFilters.push(
                    addCountryFilter(matchedCountries[i].id, matchedCountries[i].name)
                );
            }
        }

        function addUnitFilters(localFilters, input) {
            if (input.length < 2) return;
            const matchedUnits = units.filter(x => x.abbreviation?.toLowerCase().includes(input));

            for (let i = 0; i < matchedUnits.length; i++) {
                localFilters.push(
                    addGenericFilter(matchedUnits[i].abbreviation, matchedUnits[i].id, 'unit', 'Unit', 'id', 'Person')
                );
            }
        }

        function addFinancierFilter(localFilters, input) {
            if (input.length < 2) return;
            const matchedCompanies = new Set(companies.filter(company => company.state !== 2 && company.name.toLowerCase().includes(input.toLowerCase())).slice(0, 10));
        
            for (const company of matchedCompanies) {
                localFilters.push(addGenericFilter(company.name, company.id, 'financier', 'Financier', 'id', null));
            }
        }

        function addSoftwareFilters(localFilters, input) {
            if (input.length < 2) return;
            const matchedSoftware = software.filter(x => x.label?.toLowerCase().includes(input));

            for (let i = 0; i < matchedSoftware.length; i++) {
                localFilters.push(
                    addGenericFilter(matchedSoftware[i].label, matchedSoftware[i].id, 'software', 'Software', 'id', 'Person')
                );
            }
        }

        function addLoisFilter(localFilters, input) {
            if (entities === null) return;
            if (input.length < 2) return;
            if(input.length == null) return;
            const matchedLois = entities.filter(x => x.loisCode?.toLowerCase().includes(input))
            if (input.length >= 4) {
                if(input.length == null) return;
                for (let i = 0; i < matchedLois.length; i++) {
                localFilters.push(addGenericFilter(matchedLois[i].loisCode, matchedLois[i].loisCode, 'lois-code', 'Lois Code', 'id', 'Person'));
                }
            }
        }

        function addExternalReferenceFilter(localFilters, input) {
            if (input.length < 2) return;

            if (input.length >= 2) {
                localFilters.push(addGenericFilter(input, input, 'external-reference-code', 'External reference', undefined));
            }
        }
        
        function addKeywordFilter(localFilters, input) {
            if (input.length < 2) return;
            const matchedKeyword = new Set(keywords.filter(x => x.label?.toLowerCase().includes(input)).slice(0, 10));

            for (const keyword of matchedKeyword) {
                localFilters.push(addGenericFilter(keyword.label, keyword.id, 'keywords', 'Keywords', 'id', null));
            }
        }

        function addArchivalReasonFilter(localFilters, input) {
            const matchedArchivalReason = archivalReasons.filter(x => x.name?.toLowerCase().includes(input));
            if (input.length < 2) return;

            if (input.length >= 2) {
                for (let i = 0; i < matchedArchivalReason.length; i++) {
                    localFilters.push(addGenericFilter(convertToHumanReadable(matchedArchivalReason[i].name), matchedArchivalReason[i].id.toString(), 'archivalReason', 'Archival Reason', 'id', null));
                }
            }
        }

        function addExternalStageFilter(localFilters, input) {
            if (input.length < 2) return;

            const words = input.split(' ');
            const enumValue = words.join(''); // remove spaces
            const matched = new Set(externalStages.filter(x => x.name?.toLowerCase().includes(enumValue)).slice(0, 10));

            for (const externalStage of matched) {
                localFilters.push(addGenericFilter(convertToHumanReadable(externalStage.name), externalStage.name.toLowerCase(), 'external-stage', 'External stage', 'id', null));
            }
          }
     
        function addInternalStageFilter(localFilters, input) {
            if (input.length < 2) return;

            const words = input.split(' ');
            const enumValue = words.join(''); // remove spaces
            const matched = new Set(internalStages.filter(x => x.name?.toLowerCase().includes(enumValue)).slice(0, 10));

            for (const internalStages of matched) {
                localFilters.push(addGenericFilter(convertToHumanReadable(internalStages.name), internalStages.name.toLowerCase(), 'internal-stage', 'Internal stage', 'id', null));
            }
        }

        function addProjectStageFilter(localFilters, input) {
            if (input.length < 2) return;

            const words = input.split(' ');
            const enumValue = words.join(''); // remove spaces
            const matched = new Set(projectStages.filter(x => x.name?.toLowerCase().includes(enumValue)).slice(0, 10));

            for (const projectStages of matched) {
                localFilters.push(addGenericFilter(convertToHumanReadable(projectStages.name), projectStages.name.toLowerCase(), 'project-stage', 'Project stage', 'id', null));
            }
        }

        function addMaconomyTenderFilter(localFilters, input) {
            if (input.length < 2) return;
            if (input.length >= 2) {
                if (/^\d+$/.test(input)) { // Check if the input contains only digits
                    localFilters.push(addGenericFilter(input, input, 'maconomy-id', 'Maconomy number', 'id', null));
                }
            }
        }

        function addSdgFilters(localFilters, input) {
            if (input.length < 2) return;
            const matched = sdgs.filter(x => x.description?.toLowerCase().includes(input));

            for (let i = 0; i < matched.length; i++) {
                localFilters.push(
                    addGenericFilter(matched[i].description, matched[i].id, 'sdgs', 'Sdg', 'id', 'Person')
                );
            }
        }

        function addExperimentalFacilityFilters(localFilters, input) {
            if (input.length < 2) return;
            const matched = experimentalFacilities.filter(x => x.label?.toLowerCase().includes(input));

            for (let i = 0; i < matched.length; i++) {
                localFilters.push(
                    addGenericFilter(matched[i].label, matched[i].id, 'facility', 'Facility', 'id', 'Contact')
                );
            }
        }
        
        function addMoonShotFilters(localFilters, input) {
            if (input.length < 2) return;
            const matched = moonShots.filter(x => x.label?.toLowerCase().includes(input));

            for (let i = 0; i < matched.length; i++) {
                localFilters.push(
                    addGenericFilter(matched[i].label, matched[i].id, 'moonshot', 'Moonshot', 'id', 'Rocket')
                );
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilters, searchInput, regions, countries]);

    return (
        <SmartSearch
            input={searchInput}
            setInput={setSearchInput}
            onChange={value => setSearchInput(value)}
            suggestions={suggestions}
            onSearch={filters => {
                filters = filters.filter(f => f)
                setSearchFilters(filters);
                if (collectionId) {
                    setSelection([]);
                    setEntities(null);
                    navigateToCollection(collectionId, collectionTab);
                }
            }}
            activeSearchFilters={searchFilters}
        />
    );
}

export default Search;
