import React, { useState, useEffect, createContext, useContext } from 'react';

import {IScanSourceDto} from "../Api/Dtos/IScanSourceDto";
import {IUnitDto} from "../Api/Dtos/IUnitDto";
import {IDepartmentDto} from "../Api/Dtos/IDepartmentDto";
import {IProgramDto} from "../Api/Dtos/IProgramDto";
import {IKeywordDto} from "../Api/Dtos/IKeywordDto";
import {HttpGet, HttpPost} from "ibis-design-system/lib/HelperFunctions/HttpHelper";
import Settings from "../Settings";
import {IProjectStages} from "../Api/Dtos/IStageDto";
import {IBasicGlobalConfigEntity, IGlobalConfigResponse} from "../Api/Dtos/IBasicGlobalConfigEntity";
import {ICurrencyDto} from "../Api/Dtos/ICurrencyDto";
import {ISdgs} from "../Api/Dtos/ISdgs";
import {IMissionArea} from "../Api/Dtos/IMissionArea";
import { IFinanceGeneralDto } from '../Api/Dtos/IFinanceGeneralDto';
import { ICompanyDto } from '../Api/Dtos/ICompanyDto';
import { IArchivalReasonsDto } from '../Api/Dtos/IArchivalReasonsDto';
import { IInternalStages } from '../Api/Dtos/IInternalStages';
import { IExternalStages } from '../Api/Dtos/IExternalStages';

export interface IGlobalConfigContext {
    // A list of all Units within Deltares.
    units: IUnitDto[];
    // A list of all Departments within Deltares. Contains an ID reference to an IUnitDto.
    departments: IDepartmentDto[];
    // A list of data facilities from Deltares. Used on the projects page.
    dataFacilities: IBasicGlobalConfigEntity[];
    // A list of knowledge networks from Deltares.
    knowledgeNetworks: IBasicGlobalConfigEntity[];
    // A list of experimental facilities from Deltares. Used on the projects tab.
    experimentalFacilities: IBasicGlobalConfigEntity[];
    // A list of software products and models from Deltares. Used on the projects tab.
    softwareAndModels: IBasicGlobalConfigEntity[];
    // A list of all available programs within Deltares.
    programs: IProgramDto[];
    // A list of scan sources a user can select on the Tender tab -> Source. Sources come from the Source API.
    scanSources: IScanSourceDto[];
    // A list of Roles where one can be selected on the Finance tab -> Budget
    roles: any[]; // TODO create interface for type - Kanban ticket: IBIS-299.
    keywords: IKeywordDto[];
    dppKeywords: IKeywordDto[];
    natures: any[];
    // A list of stages for a Tender. Comes from the Tender API instead of the global config.
    // A list of reasons for status change. Used for adding / updating timeline events on the Tender tab.
    reasonsForStatusChange: IBasicGlobalConfigEntity[];
    // A list of types for a Tender connection. used on the Tender tab when creating a connection.
    tenderConnectionType: IBasicGlobalConfigEntity[];
    // A list of types for a Project connection. used on the Project tab when creating a connection.
    projectConnectionType: IBasicGlobalConfigEntity[];
    // A list of different financing types. Used on the Finance tab.
    financingTypes: IBasicGlobalConfigEntity[];
    // A list of selecting methods. Used on the finance page.
    selectingMethods: IBasicGlobalConfigEntity[];
    // A list of all possible legal frameworks that can be selected on the Finance tab -> Tax
    legalFrameworks: IBasicGlobalConfigEntity[];
    // A list of possible operational goals for a program. Used on the project tab when creating or editing a Program.
    operationalGoals: IBasicGlobalConfigEntity[];
    // A list of key technologies from Deltares. Used on the Projects tab.
    keyTechnologies: IBasicGlobalConfigEntity[];
    // A list of Deltares mission areas.
    deltaresMissionArea: IMissionArea[];
    // A list of financing frameworks. Used on the Projects tab.
    financingFrameworks: IBasicGlobalConfigEntity[];
    regions: any[];
    countries: any[];
    relationTypes: any[];
    currencies: any[];
    contactPersonRoles: any[];
    deltaresBenefits: any[];
    processSchema: any[];
    sdgs: ISdgs[],
    templateDownloadOptions: IBasicGlobalConfigEntity[];
    maconomy: IFinanceGeneralDto[]
    deltaresRole: IBasicGlobalConfigEntity[];
    companies: ICompanyDto[];
    archivalReasons:IArchivalReasonsDto[];
    projectStages: IProjectStages[];
    internalStages: IInternalStages[];
    externalStages: IExternalStages[];
    moonshots: IBasicGlobalConfigEntity[];


    /**
     * Possible addition.
     * It's not yet know what the financing frameworks will look like, and if they are project specific or not.
     * For more information and updates check the Kanban ticket: IBIS-300
     */
    // financingFrameworks: any[]
}

const INITIAL_STATE: IGlobalConfigContext = {
    dataFacilities: [],
    experimentalFacilities: [],
    keyTechnologies: [],
    knowledgeNetworks: [],
    softwareAndModels: [],
    units: [],
    departments: [],
    programs: [],
    scanSources: [],
    roles: [],
    keywords: [],
    dppKeywords: [],
    natures: [],
    reasonsForStatusChange: [],
    tenderConnectionType: [],
    projectConnectionType: [],
    financingTypes: [],
    selectingMethods: [],
    legalFrameworks: [],
    operationalGoals: [],
    regions: [],
    countries: [],
    relationTypes: [],
    currencies: [],
    contactPersonRoles: [],
    processSchema: [],
    deltaresBenefits: [],
    deltaresMissionArea: [],
    sdgs: [], // Sustainable deltas
    financingFrameworks: [],
    templateDownloadOptions: [],
    maconomy: [],
    deltaresRole: [],
    companies:[],
    archivalReasons:[],
    projectStages:[],
    internalStages: [],
    externalStages: [],
    moonshots: [],
}

export const GlobalConfigContext = createContext<IGlobalConfigContext>(INITIAL_STATE);

/**
 * Import this hook to get access to all of the Global Config data.
 */
export function useGlobalConfig() {
    return useContext(GlobalConfigContext);
}

interface IGlobalConfigProviderProps {
    children: React.ReactNode;
}

export function GlobalConfigProvider(props: IGlobalConfigProviderProps) {
    const [config, setConfig] = useState<IGlobalConfigContext>(INITIAL_STATE);

    useEffect(() => {
        fetchGlobalConfigData();
    }, []);

    const fetchGlobalConfigData = async (): Promise<void> => {
        HttpGet(`${Settings.apiUrls.globalConfig}/keywords`, null, true).then((response: IGlobalConfigResponse<IKeywordDto[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, keywords: response.entities };
        })
        );
        HttpGet(`${Settings.apiUrls.globalConfig}/dpp-keywords`, null, true).then((response: IGlobalConfigResponse<IKeywordDto[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, dppKeywords: response.entities };
        })
        );
        HttpGet(`${Settings.apiUrls.tenders}/projects/stages`, null, true).then((response: IProjectStages[]) => setConfig((prevConfig) => {
            return { ...prevConfig, projectStages: response };
        })
        );
        HttpGet(`${Settings.apiUrls.tenders}/tenders/internal-stages`, null, true).then((response: IInternalStages[]) => setConfig((prevConfig) => {
            return { ...prevConfig, internalStages: response };
        })
        );
        HttpGet(`${Settings.apiUrls.tenders}/tenders/external-stages`, null, true).then((response: IExternalStages[]) => setConfig((prevConfig) => {
            return { ...prevConfig, externalStages: response };
        })
        );
        HttpGet(`${Settings.apiUrls.globalConfig}/organisation-natures`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, natures: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/departments`, null, true).then((response: IGlobalConfigResponse<IDepartmentDto[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, departments: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/units`, null, true).then((response: IGlobalConfigResponse<IUnitDto[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, units: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.sources}/scansources`, null, true).then((response: IGlobalConfigResponse<IScanSourceDto[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, scanSources: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.tenders}/archivalReasons`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, archivalReasons: response };
            });
        });
        HttpGet(`${Settings.apiUrls.globalConfig}/reason-for-status-change`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, reasonsForStatusChange: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/tender-connection-type`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, tenderConnectionType: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/project-connection-type`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, projectConnectionType: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/programs`, null, true).then((response: IGlobalConfigResponse<IProgramDto[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, programs: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/regions`, null, true).then((response: IGlobalConfigResponse<any[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, regions: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/moonshots`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, moonshots: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/countries`, null, true).then((response: IGlobalConfigResponse<any[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, countries: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/organisation-relations`, null, true).then((response: IGlobalConfigResponse<any[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, relationTypes: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/financing-type`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, financingTypes: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/selecting-method`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, selectingMethods: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/data/currencies`, null, true).then((response: ICurrencyDto[]) => setConfig((prevConfig) => {
            return { ...prevConfig, currencies: response };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/data/contact-person-roles`, null, true).then((response: any) => setConfig((prevConfig) => {
            return { ...prevConfig, contactPersonRoles: response };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/data/deltares-benefits`, null, true).then((response: any) => setConfig((prevConfig) => {
            return { ...prevConfig, deltaresBenefits: response };
        })
        );


        HttpGet(`${Settings.apiUrls.globalConfig}/legal-framework`, null, true).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) => setConfig((prevConfig) => {
            return { ...prevConfig, legalFrameworks: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/data/sdgs`, null, true).then((response: ISdgs[]) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, sdgs: response };
            });
        });

        HttpGet(`${Settings.apiUrls.globalConfig}/knowledge-operational-goal`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, operationalGoals: response.entities };
            });
        });

        HttpGet(`${Settings.apiUrls.globalConfig}/deltares-key-technology`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, keyTechnologies: response.entities };
            });
        });

        HttpGet(`${Settings.apiUrls.globalConfig}/software-and-model`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, softwareAndModels: response.entities };
            });
        });

        HttpGet(`${Settings.apiUrls.globalConfig}/experimental-facility`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, experimentalFacilities: response.entities };
            });
        });

        HttpGet(`${Settings.apiUrls.globalConfig}/data-facility`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, dataFacilities: response.entities };
            });
        });
        HttpGet(`${Settings.apiUrls.globalConfig}/deltares-knowledge-network`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, knowledgeNetworks: response.entities };
            });
        });
        HttpGet(`${Settings.apiUrls.globalConfig}/data/deltaresMissionArea`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, deltaresMissionArea: response };
            });
        });
        HttpGet(`${Settings.apiUrls.globalConfig}/financing-framework`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, financingFrameworks: response.entities };
            });
        });
        HttpGet(`${Settings.apiUrls.globalConfig}/project-deltares-role`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, deltaresRole: response.entities };
            });
        });
        HttpPost(`${Settings.apiUrls.globalConfig}/project-export-template/templates`, true).then((response: IBasicGlobalConfigEntity[]) => setConfig((prevConfig) => {
            return { ...prevConfig, templateDownloadOptions: response };
        })
        );

        HttpGet(`${Settings.apiUrls.cci}/companies`, null, true).then((response: any) => {
            setConfig((prevConfig) => {
                return { ...prevConfig, companies: response.entities };
            });
        });

    };
    return (
        <GlobalConfigContext.Provider value={config}>
            {props.children}
        </GlobalConfigContext.Provider>
    );
}
export function useArchivalReasons(): IArchivalReasonsDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useArchivalReasons must be used within a GlobalConfigProvider');
    }
    return context.archivalReasons;
}
export function useProjectStages(): IProjectStages[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useProjectStages must be used within a GlobalConfigProvider');
    }
    return context.projectStages;
}
export function useInternalStages(): IInternalStages[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useInternalStages must be used within a GlobalConfigProvider');
    }
    return context.internalStages;
}
export function useExternalStages(): IExternalStages[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useExternalStages must be used within a GlobalConfigProvider');
    }
    return context.externalStages;
}
export function useCompanies(): ICompanyDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useCompanies must be used within a GlobalConfigProvider');
    }
    return context.companies;
}

export function useKeywords(): IKeywordDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useKeywords must be used within a GlobalConfigProvider');
    }
    return context.keywords;
}

export function useDPPKeywords(): IKeywordDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDPPKeywords must be used within a GlobalConfigProvider');
    }
    return context.dppKeywords;
}

export function useNatures(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useNatures must be used within a GlobalConfigProvider');
    }
    return context.natures;
}

export function useNature(guid: string) {
    const natures = useNatures();
    if (!natures || natures.length === 0) return null;
    return natures.find((x) => x.id === guid);
}

export function useDepartments(): IDepartmentDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDepartments must be used within a GlobalConfigProvider');
    }
    return context.departments;
}

export function useUnits(): IUnitDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useUnits must be used within a GlobalConfigProvider');
    }
    return context.units;
}

export function useScanSources(): IScanSourceDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useScanSources must be used within a GlobalConfigProvider');
    }
    return context.scanSources;
}


export function useReasonsForStatusChange(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useReasonsForStatusChange must be used within a GlobalConfigProvider');
    }
    return context.reasonsForStatusChange;
}

export function useTenderConnectionType(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useTenderConnectionType must be used within a GlobalConfigProvider');
    }
    return context.tenderConnectionType;
}

export function useProjectConnectionType(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useProjectConnectionType must be used within a GlobalConfigProvider');
    }
    return context.projectConnectionType;
}

export function useFinancingType(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useFinancingType must be used within a GlobalConfigProvider');
    }
    return context.financingTypes;
}

export function usePrograms(): IProgramDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('usePrograms must be used within a GlobalConfigProvider');
    }
    return context.programs;
}

export function useRegions(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useRegions must be used within a GlobalConfigProvider');
    }
    return context.regions;
}

export function useMoonshots(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useMoonshots must be used within a GlobalConfigProvider');
    }
    return context.moonshots;
}

export function useCountries(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useCountries must be used within a GlobalConfigProvider');
    }
    return context.countries;
}

export function useRelationTypes(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useRelationTypes must be used within a GlobalConfigProvider');
    }
    return context.relationTypes;
}

export function useSelectingMethods(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useSelectingMethods must be used within a GlobalConfigProvider');
    }
    return context.selectingMethods;
}

export function useCurrencies(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useCurrencies must be used within a GlobalConfigProvider');
    }
    return context.currencies;
}

export function useContactPersonRoles(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useContactPersonRoles must be used within a GlobalConfigProvider');
    }
    return context.contactPersonRoles;
}

export function useDeltaresBenefits() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDeltaresBenefits must be used within a GlobalConfigProvider');
    }
    return context.deltaresBenefits;
}

export function useDeltaresBenefit(guid: string) {
    const deltaresBenefits = useDeltaresBenefits();
    if (!deltaresBenefits || deltaresBenefits.length === 0) return null;
    return deltaresBenefits.find((x: any) => x.id === guid);
}

export function useProcessSchema() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useProcessSchema must be used within a GlobalConfigProvider');
    }
    return context.processSchema;
}

export function useLegalFrameworks() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useLegalFrameworks must be used within a GlobalConfigProvider');
    }
    return context.legalFrameworks;
}

export function useOperationalGoals() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useOperationalGoals must be used within a GlobalConfigProvider');
    }
    return context.operationalGoals;
}

export function useSdgs() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useSdgs must be used within a GlobalConfigProvider');
    }
    return context.sdgs;
}

export function useKeyTechnologies() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useKeyTechnologies must be used within a GlobalConfigProvider');
    }
    return context.keyTechnologies;
}

export function useSoftwareAndModels() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useSoftwareAndModels must be used within a GlobalConfigProvider');
    }
    return context.softwareAndModels;
}

export function useExperimentalFacilities() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useExperimentalFacilities must be used within a GlobalConfigProvider');
    }
    return context.experimentalFacilities;
}

export function useDataFacilities() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDataFacilities must be used within a GlobalConfigProvider');
    }
    return context.dataFacilities;
}

export function useKnowledgeNetworks() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useKnowledgeNetworks must be used within a GlobalConfigProvider');
    }
    return context.knowledgeNetworks;
}

export function useDeltaresMissionAreas() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDeltaresMissionAreas must be used within a GlobalConfigProvider');
    }
    return context.deltaresMissionArea;
}

export function useFinancingFrameworks() {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useFinancingFrameworks must be used within a GlobalConfigProvider');
    }
    return context.financingFrameworks;
}
export function useTemplateDownloadOptions(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useTemplateDownloadOptions must be used within a GlobalConfigProvider');
    }
    return context.templateDownloadOptions;
}
export function useMaconomy(): IFinanceGeneralDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useMaconomy must be used within a GlobalConfigProvider');
    }
    return context.maconomy;
}
export function useDeltaresRole(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDeltaresRole must be used within a GlobalConfigProvider');
    }
    return context.deltaresRole;
}
