import React from 'react';
import ReactDOM from 'react-dom';
import 'es6-shim';
import 'office-ui-fabric-react/dist/css/fabric.min.css';
import 'react-circular-progressbar/dist/styles.css';
import {initializeIcons} from '@fluentui/react';

import LoginProvider from 'ibis-design-system/lib/Providers/LoginProvider';
import {HttpGet} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import ThemeProvider from 'ibis-design-system/lib/Providers/ThemeProvider';
import GoogleMapsProvider from 'ibis-design-system/lib/Providers/GoogleMapsProvider';
import WebSocketConnectionProvider from 'ibis-design-system/lib/Providers/WebSocketConnectionProvider';

import IEPage, {
    isUsingInternetExplorer,
} from 'ibis-design-system/lib/components/core/IEPage';

import './index.css';
import App from './App';
import Settings, {SetSettings} from './Settings';
import * as serviceWorker from './serviceWorker';
import NavigationProvider from "ibis-design-system/lib/Providers/NavigationProvider";
import ApplicationsProvider from "ibis-design-system/lib/Providers/ApplicationsProvider";
import {GlobalConfigProvider} from "./Hooks/GlobalConfigContext";
import HttpCacheProvider from "ibis-design-system/lib/Providers/HttpCacheProvider";
import { isProject } from './Utils/isProject';

if (isUsingInternetExplorer()) {
    ReactDOM.render(<IEPage/>, document.getElementById('root'));
} else {
    initializeIcons();

    let settingsPath = '/appSettings.json';
    if (process.env.NODE_ENV === 'development')
        settingsPath = '/appSettings.dev.json';

    HttpGet(settingsPath).then((response) => {
        SetSettings(response);
        ReactDOM.render(
            <HttpCacheProvider>
                <WebSocketConnectionProvider url={`${Settings.apiUrls.portal}/realtime`}>
                    <ThemeProvider>
                        <LoginProvider
                            api={Settings.apiUrls.tenders}
                            ssoApi={Settings.apiUrls.singleSignOn}
                            sso={Settings.uiUrls.singleSignOn}
                            logApi={Settings.apiUrls.portal}
                            returnUrl={window.location.href}
                        >
                            <ApplicationsProvider portalApi={Settings.apiUrls.portal}>
                                <GoogleMapsProvider apiKey={Settings.mapsKey}>
                                    <GlobalConfigProvider>
                                        <NavigationProvider defaultCollection={isProject()? "published-projects": "active-tenders"} defaultCollectionTab="activity">
                                            <App />
                                        </NavigationProvider>
                                    </GlobalConfigProvider>
                                </GoogleMapsProvider>
                            </ApplicationsProvider>
                        </LoginProvider>
                    </ThemeProvider>
                </WebSocketConnectionProvider>
            </HttpCacheProvider>,
            document.getElementById('root')
        );
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
